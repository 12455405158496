export const SCALE_APP = +sessionStorage.scaleApp ? +sessionStorage.scaleApp : 0.75;

export const ZOOM_OPTIONS = [0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.33, 1.4, 1.5];
// Dates displayed to the user or to match the DB format.
export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const MONTH_ONLY_FORMAT = 'MMM';
export const YEAR_FORMAT = 'YYYY';
export const DATE_TIME_FORMAT = 'M/D/YYYY hh:mm a';
export const DATE_MEDIUM_FORMAT = 'MMM D, YYYY hh:mm a';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
export const FILES_DATE = 'MMM D, YYYY';
export const CURRENCY_FORMAT = '$#,###,###,##0.';
export const CURRENCY_FULL_FORMAT = '$#,###,###,##0.00';
export const CURRENCY_FULL_EXCEL = '"$"#,##0.00';
export const PERCENT_FORMAT = '##0.00%';
export const PERCENT_SHORT_FORMAT = '##0%';
export const PERCENT_SHORT_FORMAT1 = '#0%';
export const PERCENT_FORMAT_BEFORE = '%#,###,###,##0.00';
export const DATE_SHORT_FORMAT = 'MM-DD-YY';
export const NUMBER_FULL_FORMAT = '#,###,###,##0.00';

// TOKENS
export const MIX_PANEL_TOKEN = 'bf47db5525f8329e3fc9644eab5d2fb3';

// Timers
export const DAYS_TO_DISPLAY_COMPLETED_TASKS = 7;
export const ROLLING_CYCLE_MAX_MONTHS = 48;

export const NOTE_HOVER_TIME = 3000;

export const MONTHS = [
   'January',
   'February',
   'March',
   'April',
   'May',
   'June',
   'July',
   'August',
   'September',
   'October',
   'November',
   'December',
   'Annual',
];

export const MONTHS_CONVERT = {
   jan: 'January',
   feb: 'February',
   mar: 'March',
   apr: 'April',
   may: 'May',
   jun: 'June',
   jul: 'July',
   aug: 'August',
   sep: 'September',
   oct: 'October',
   nov: 'November',
   dec: 'December',
   annual: 'Annual',
};

export const ERROR_COLOR = '#AA0B06';
export const WARNING_COLOR = '#F5CD19';
export const SUCCESS_COLOR = '#5C9E52';
// export const PRIMARY_COLOR = 'rgb(107,146,65)';
export const PRIMARY_COLOR = '#769548';

// Images and icons
export const LOCK_ICON = '/images/lock.png';
export const LOCK_SMALL_ICON = '/images/lock-small.png';
export const LOCK_WHITE_SMALL_ICON = '/images/lock-white-small.png';
export const LOGO_LARGE = '/images/Farmer Metrics No Gap_H01_Light72 ppi.png';
export const LOGO = LOGO_LARGE;
export const METRICS_LOGO = '/images/Farmer_metrics_logo_v1.png';
export const SMALL_LOGO = LOGO;
export const GEARS_IMG = '/images/Layer 0.svg';
export const NETWORKING_IMG = '/images/networking.svg';
export const TOOLS_RESOURCES = '/images/Tools _ Resources.svg';
export const NOTE_EDIT_IMG = '/images/Add Note.svg';
export const FOLDER_IMG = '/images/folder.svg';
export const FOLDER_UP_IMG = '/images/Folder-arrow-up.svg';
export const DELETE_ICON = '/images/delete.png';
export const BLURRED_BACKGROUND = '/images/BlurredScreen.jpg';
export const BLURRED_BACKGROUND_DARK = '/images/Log In-Dark-Mode.png';
export const LOGO_TEXT = '/images/LogoText.png';
export const KNOWLEDGE_ICON = '/images/Knowledge Center.svg';
export const SETTING_ICON = '/images/Settings.svg';
export const FEEDBACK_ICON = '/images/Provide Feedback.svg';
export const REFER_ICON = '/images/Refer Legacy Farmer.svg';
export const HELP_CENTER_ICON = '/images/Help Box.svg';
export const FILE_ICON = '/images/file.png';
export const ADMIN_ICON = '/images/Admin.svg';
export const MY_BUSINESS_ICON = '/images/My Business.svg';
export const BIG_LOGO_ICON = '/images/LF Shield Light_300ppi_Full.png';
export const REVIEW_ICON = '/images/Leave A Review.svg';
export const ADD_ICON = '/images/plus.png';
export const EDIT_ICON = '/images/edit.png';
export const MOON_ICON = '/images/moon.svg';
export const USER_ICON = '/images/user.svg';
export const BILLING_ICON = '/images/billing.svg';
export const LOGOUT_ICON = '/images/logout.svg';
export const SAVE_ICON = '/images/save.png';
export const TREND_ANALYSIS_ICON = '/images/trend-analysis.svg';
export const AVERAGES_ICON = '/images/Averages.svg';

export const ENDPOINT = !process.env.REACT_APP_ENDPOINT
   ? '/api/graphql/'
   : `http://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
console.log('Endpoint = ', ENDPOINT);
console.log('Version = ', process.env.REACT_APP_VERSION);

export const APPBAR_HEIGHT = 74 * SCALE_APP;
export const APPBAR_SMALL_HEIGHT = 60 * SCALE_APP;
export const DRAWER_WIDTH = 340 * SCALE_APP;
export const EDIT_DRAWER_WIDTH = 300 * SCALE_APP;
export const SEAT_DRAWER_WIDTH = 420 * SCALE_APP;
export const CONTRACT_EDIT_DRAWER_WIDTH = 380 * SCALE_APP;
export const ADMIN_DRAWER = 340 * SCALE_APP;
export const ADMIN_USERS_WIDTH = ADMIN_DRAWER + 24 * SCALE_APP;
export const ADMIN_FRANCHISE_WIDTH = ADMIN_DRAWER + 24 * SCALE_APP;
export const FOLDERS_DRAWER = 314 * SCALE_APP;
export const ADMIN_PANEL_MAX_WIDTH = 480 * SCALE_APP;
export const ADMIN_PANEL_MAX_WIDTH_NEW_UI = 800 * SCALE_APP;
export const SECTION_PANEL_MAX_WIDTH = 780;
export const SECTION_PANEL_MIN_WIDTH = 420;
export const COURSE_WIDTH = 381;
export const COURSE_HEIGHT = 532;
export const MEMBERSHIP_WIDTH = 700;

export const SPACING_DEFAULT_PDF = 4;
export const BORDER_RADIUS = 6 * SCALE_APP;
export const BORDER_RADIUS_10_UNSCALED = 10 / SCALE_APP;
export const BORDER_RADIUS_10 = 10 * SCALE_APP;
export const BORDER_RADIUS_20 = 20 * SCALE_APP;
export const BORDER_RADIUS_16 = '16px';

export const LEGACY_FARMER_FRANCHISE = 'Legacy Farmer';
// File upload
export const FILE_MIME_TYPES = [
   'text/plain',
   'text/csv',
   'application/pdf',
   'application/vnd.ms-excel',
   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const BUCKET_NAME = 'legacy-farmer-test-temp-public';
// export const FILE_BUCKET =
//    process.env.NODE_ENV !== 'production' ? 'legacy-farmer-test-users' : 'legacy-farmer-prod-users';
export const FILE_BUCKET =
   process.env.REACT_APP_POOL === 'production' ? 'legacy-farmer-prod-users' : 'legacy-farmer-test-users';
export const UNDO_DURATION = 8000;
export const ROOT_ID = 'root';
export const PASSIVE_ROOT_ID = 'passiveRoot';
export const ACTIVE_ROOT_ID = 'activeRoot';

export const DIRECTORY_FILE_NAME = '...';

export const DEPRECIATION_TYPE_NAME = 'Depreciation';

export const DEFAULT_MONTH_ORDER = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
export const MONTH_CONVERT = {
   jan: 1,
   feb: 2,
   mar: 3,
   apr: 4,
   may: 5,
   jun: 6,
   jul: 7,
   aug: 8,
   sep: 9,
   oct: 10,
   nov: 11,
   dec: 12,
};

export const VIDEO_ACCEPT_KNOWLEDGE_CENTER = {'video/*': ['.avi', '.mov', '.mp4', '.mpeg']};
// export const IMAGE_ACCEPT_COURSE = 'image/png, image/gif, image/jpeg, image/webp';
export const IMAGE_ACCEPT_COURSE = {'image/*': ['.jpeg', '.png', '.webp', '.gif', '.svg']};

// Roles
export const ADMIN_GROUP = 'Admin';
export const SUPER_ADMIN_GROUP = 'SuperAdmin';
export const CLIENT_GROUP = 'Client';
export const CLIENT_SIGNUP_GROUP = 'ClientSignUp';

// Edit Constants
export const CLIENT_EDIT = 'clientEdit';
export const CLIENT_INVITE = 'clientInvite';
export const FRANCHISE_INVITE = 'franchiseInvite';
export const MEMBERSHIP_EDIT = 'membershipEdit';
export const FRANCHISE_EDIT = 'franchiseEdit';
export const ENTITY_EDIT = 'Entity';
export const USER_EDIT = 'userEdit';
export const TASK_EDIT = 'taskEdit';
export const ASSET_EDIT = 'Asset';
export const LIABILITY_EDIT = 'Liability';
export const SEAT_EDIT = 'SeatEdit';
export const FOLDER_EDIT = 'FolderEdit';
export const CONTRACT_EDIT = 'Contract';
export const SECTION_EDIT = 'section';
export const NEW_EDIT = 'new';

// Indexes for pdf and excel export
let i = 0;
export const LOAN_ANALYSIS_INDEX = i++; //0
export const ASSET_INDEX = i++; // 1
export const LIABILITY_INDEX = i++; // 2
export const BALANCE_SHEET_INDEX = i++; // 3
export const CASH_FLOW_INDEX = i++; // 4
export const FIELD_METRICS_INDEX = i++; //5
export const CONTRACTS_INDEX = i++; //6
export const TAXABLE_INCOME_INDEX = i++; //7
export const ACCOUNTABILITY_CHART_INDEX = i++; //8
export const GAME_PLAN_INDEX = i++; //9
export const BUSINESS_STRUCTURE_INDEX = i++; //10
export const PDF_COUNT = i;

// Paths from old UI and previous react-router-dom
export const ADMIN_SETUP_OLD = ':clientId?'; // '/admin/:clientId?';
export const CLIENT_DASHBOARD_OLD = ':clientId'; // '/client/:clientId';
export const CLIENT_ENTITY_DASHBOARD_OLD = ':clientId/entity/:entityId'; // '/client/:clientId/entity/:entityId';
export const ACCOUNTABILITY_CLIENT_ENTITY_OLD = ':clientId/entity/:entityId/accountability'; // '/client/:clientId/entity/:entityId/accountability';
export const ENTITY_ASSET_OLD = ':clientId/entity/:entityId/asset'; // '/client/:clientId/entity/:entityId/asset';
export const LIABILITIES_OLD = ':clientId/entity/:entityId/liability'; // '/client/:clientId/entity/:entityId/liability';
export const CONTRACT_OLD = ':clientId/entity/:entityId/contract'; // '/client/:clientId/entity/:entityId/contract';

export const LOAN_ANALYSIS_OLD = ':clientId/entity/:entityId/loanAnalysis'; //'/client/:clientId/entity/:entityId/loanAnalysis';
export const BALANCE_SHEET_OLD = ':clientId/entity/:entityId/balanceSheet'; // '/client/:clientId/entity/:entityId/balanceSheet';
export const CASH_FLOW_OLD = ':clientId/entity/:entityId/cashFlow'; // '/client/:clientId/entity/:entityId/cashFlow';
export const TAXABLE_INCOME_OLD = ':clientId/entity/:entityId/taxableIncome'; // '/client/:clientId/entity/:entityId/taxableIncome';
export const FILES_OLD = ':clientId/entity/:entityId/files'; // '/client/:clientId/entity/:entityId/files';
export const LOAN_AMORTIZATION_OLD = ':clientId/entity/:entityId/loanAmortization'; // '/client/:clientId/entity/:entityId/loanAmortization';
export const CLIENT_TASK_NOTES_OLD = ':clientId/entity/:entityId/task';
export const ADMIN_USER_OLD = ':userId'; // '/admin/user/:userId?';

export const FOLDER_OLD = ':folderId'; // '/admin/folder/:folderId?';

//Paths
export const DEFAULT_PATH = '/';
export const ADMIN_PATH = 'admin';
export const ADMIN_FULL_PATH = ADMIN_PATH; // `${HOME_PATH}/${ADMIN_PATH}`;
export const ADMIN_SETUP_PATH = 'setup';
export const CLIENT_DASHBOARD_PATH = 'client';
export const CLIENT_SIGNUP_PATH = 'signup';
export const CLIENT_FREEMIUM_PATH = 'freemium';
export const CLIENT_FREE_TRIAL_PATH = 'free-trial';
export const HOME_PATH = 'home';
export const CLIENT_MAIN_DASHBOARD = 'dashboard';
export const USER_PROFILE = 'profile';
export const VERIFY_INFORMATION = 'verify';
export const CLIENT_ENTITY_DASHBOARD_PATH = 'dashboard';
export const ACCOUNTABILITY_CLIENT_ENTITY_PATH = 'accountability';
export const CLIENT_ENTITY_PATH = 'entity';
export const ENTITY_ASSET_PATH = 'asset';
export const LIABILITIES_PATH = 'liability';
export const CONTRACT_PATH = 'contract';
export const CASH_EDIT_PATH = 'cashEdit';
export const FUTURE_EDIT_PATH = 'futureEdit';
export const HEDGE_EDIT_PATH = 'hedgeEdit';
export const BUSINESS_PATH = 'business';
export const BUSINESS_FULL_PATH = `${CLIENT_DASHBOARD_PATH}/${BUSINESS_PATH}`;
export const CLIENT_DEFAULT_PATH = `${CLIENT_DASHBOARD_PATH}/:section`;
export const TOOL_PATH = 'tool';
export const GAME_PLAN_PATH = 'gamePlan';
export const TOOL_FULL_PATH = `${CLIENT_DASHBOARD_PATH}/${TOOL_PATH}`; //`${HOME_PATH}/${TOOL_PATH}`;

//lms
export const LMS_DASHBOARD_PATH = 'course';
// export const LMS_UNIT_PATH = 'unit';
export const LMS_SEARCH_PATH = 'search';
//lms end

export const LOAN_ANALYSIS_PATH = 'loanAnalysis';
export const PROFIT_LOSS_PATH = 'profitLost';
export const CROP_SUMMARY_PATH = 'cropSummary';
// export const LOAN_ANALYSIS_PATH = '/client/:clientId/entity/:entityId/loanAnalysis/:mode?';
export const BALANCE_SHEET_PATH = 'balanceSheet';
// export const BALANCE_SHEET_PATH = '/client/:clientId/entity/:entityId/balanceSheet/:mode?';
export const CASH_FLOW_PATH = 'cashFlow';
export const CASH_FLOW_FULL_PATH = `${BUSINESS_FULL_PATH}/${CASH_FLOW_PATH}`;
export const TAXABLE_INCOME_PATH = 'taxableIncome';
export const FILES_PATH = 'files';
export const CHATROOM_PATH = 'chatroom';
// export const CASH_FLOW_PATH = '/client/:clientId/entity/:entityId/cashFlow/:mode?';
export const LOAN_AMORTIZATION_PATH = 'loanAmortization';
export const CLIENT_TASK_NOTES_PATH = 'task';
export const ADMIN_USER_PATH = 'user';
export const ADMIN_MEMBERSHIP_PATH = 'membership';
export const ADMIN_USERS_PATH = 'user';
export const ADMIN_COURSES_PATH = 'course';
export const ADMIN_FRANCHISES_PATH = 'licensee';
export const SECTION_PATH = 'section';
export const MODULE_PATH = 'module';
export const ADMIN_COURSE_PATH = 'course';
export const ADMIN_TEAM_MEMBERS_PATH = 'teamMember';
export const ADMIN_TEAM_MEMBER_PATH = 'teamMember';
export const SETTINGS_PATH = 'settings';
export const LMS_KNOWLEDGE_LIBRARY_PATH = 'knowledge-center';

//Knowledge Library
export const CLIENT_KNOWLEDGE_LIBRARY_DASHBOARD_PATH = 'courses';
export const CLIENT_KNOWLEDGE_COURSE_DETAILS_PATH = '/client/:clientId/course/:courseId/unit/:unitId';
export const CLIENT_KNOWLEDGE_COURSE_SEARCH_PATH = '/client/:clientId/csearch/:searchParams?';

// News feed
export const NEWS_FEED_PATH = '/client/:clientId/newsFeed';

export const FOLDERS_PATH = 'folder';
export const FOLDER_PATH = 'folder';

// Calendar
export const CALENDAR_PATH = 'calendar';

// Notification
export const NOTIFICATIONS_LIST = 'notifications';

export const EDIT_PATH = 'edit';

export const GAME_PLAN_TABBAR = ['Vision', 'Goals', 'Rocks', 'Team Meeting', 'Team To Dos'];

export const DEFAULT_SUMMARY =
   '<p><strong>Total Clients&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;40%\t\t\t\t\t\t\t\t\tEmpire</strong></p><p><strong>Legends</strong></p>';

export const KNOWLEDGE_LIBRARY_PATH = 'knowledge-center';
export const KNOWLEDGE_LIBRARY_NEW_COURSE_PATH = 'knowledge-center/new-course';
export const NEW_COURSE_PATH = 'new-course';
export const KNOWLEDGE_LIBRARY_COURSE_PATH = 'knowledge-center/course/:courseId';
export const COURSE_ID_PATH = ':courseId';
export const COURSE_PATH = 'course';
export const KNOWLEDGE_LIBRARY_COURSE_NO_PARAM_PATH = 'course';
export const KNOWLEDGE_LIBRARY_FULL_PATH = 'client/tool/knowledge-center';

export const URL_S3 = 'https://legacy-farmer-test-temp-public.s3.us-east-2.amazonaws.com';
export const URL_S3_UPLOAD = 'https://legacy-farmer-test-temp-public.s3.us-east-2.amazonaws.com/lms/upload/';

export const PRIVACY_POLICY = 'https://www.legacyfarmer.org/privacy-policy';
export const TERMS = 'https://www.legacyfarmer.org/terms';

export const BORROWING_LOGO = '/images/Borrowing Logo.png';
export const LOAN_AMORTIZATION_LOGO = '/images/Loan Amortization Logo.png';
export const ASSETS_LOGO = '/images/Assets Logo.png';
export const LIABILITIES_LOGO = '/images/Liabilities Logo.png';
export const BALANCE_SHEET_LOGO = '/images/Balance Sheet Logo.png';
export const CASH_FLOW_LOGO = '/images/Cash Flow Logo.png';
export const CONTRACT_LOGO = '/images/Contract Logo.png';
export const ACCOUNT_LOGO = '/images/TeamChart.png';
export const LOGO_LARGEST_RIGHT = '/images/LF Shield Light.png';
export const SMALL_LOGO_WHITE = '/images/Farmer Metrics-H02_White_300dpi.png';
export const TABLE_CONTENTS_LOGO = '/images/Table Of Contents.png';
export const BACKGROUND_IMAGE_PDF = '/images/Background Pdf.png';
export const COMMUNITY_ICON = '/images/Community.svg';
export const CROP_PLAN_IMAGE_PDF = '/images/CROPPLANPROFITANDLOSS.png';

export const WSENDPOINT =
   process.env.REACT_APP_POOL === 'production'
      ? `wss://app.legacyfarmer.com/graphql`
      : `ws://legacyfarmer-test-env-docker.eba-x73qzpab.us-east-2.elasticbeanstalk.com/graphql`;
console.log('WSEndpoint = ', WSENDPOINT);
export const COMMUNITY_PATH = 'community';
export const COMMUNITY_FULL_PATH = `${CLIENT_DASHBOARD_PATH}/${COMMUNITY_PATH}`;
export const CHAT_PATH = 'chat';
export const TYPE_CHAT = {
   public: 'public',
   private: 'private',
};
export const ADD_CHAT_IMAGE = '/images/add-chat.png';
export const FEEDBACK_PATH = 'feedback';

export const INTERCOM_APP_ID = 'rd48dd93';
export const INTERCOM_API_BASE = 'https://api-iam.intercom.io';
export const MIXPANEL_TOKEN =
   process.env.REACT_APP_POOL === 'production'
      ? 'bf47db5525f8329e3fc9644eab5d2fb3'
      : '8978076a8e41ae07d1c440cb0e225141';

export const MAX_AROUND = 16;
export const MAX_TOTAL = 150;
export const POLL_INTERVAL_DURATION =
   process.env.REACT_APP_POOL === 'production' || localStorage.noPolling !== 'true' ? 5000 : 0; //ms

export const NOMFA = 'NOMFA';
export const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA';

export const SETTINGS_PROFILE_PATH = 'profile';
export const SETTINGS_PROFILE_FULL_PATH = '/client/settings/profile';
export const SETTINGS_FULL_PATH = '/client/settings';
export const SETTINGS_2FA_PATH = '2fa';
export const SETTINGS_2FA_FULL_PATH = '/client/settings/2fa';
export const SETTINGS_APPEARANCE_PATH = 'appearance';
export const SETTINGS_APPEARANCE_FULL_PATH = '/client/settings/appearance';
export const LOGO_DARK = '/images/Farmer Metrics No Gap_H01_Dark72 ppi.png';
export const SETTINGS_ACCOUNT_PATH = 'account';
export const SETTINGS_BILLING_PATH = 'billing';

export const DARK_MODE_COLORS = {
   Background_1: '#1D1D1D',
   Background_2: '#212121',
   Text_Icons_Active: '#FFFFFF',
   Text_Icons_Disabled: '#999999',
   Card_1: '#303030',
   Card_2_Stroke: '#434343',
   Primary_Green: '#769548',
   Light_Green: '#4C5343',
   Large_Icon_Background: '#454545',
   Dark_Green: '#ABC18D',
};
export const SCHEDULE_LINK = 'https://meetings.hubspot.com/legacyfarmer/activation-call';
